export class Product {
    article_number: string;
    brand: any;
    buy_only_discounts?: any;
    category: any;
    color?: any;
    currency: string;
    date_created: Date;
    description?: string;
    description_de?: string;
    ean: string;
    id: number;
    images: any;
    is_active: boolean;
    is_buy_only: boolean;
    is_influencer_offer: boolean;
    main_image: any;
    max_purchase_qty?: any;
    name: string;
    name_de?: string;
    offer_end_date: Date;
    owner: any;
    post_briefing?: any;
    post_sample_images?: any;
    post_sample_photo_de?: string;
    post_sample_photo_en?: string;
    post_sample_tags_de?: string;
    post_sample_tags_en?: string;
    post_sample_text_de?: string;
    post_sample_text_en?: string;
    posts_qty?: number;
    price: any;
    size?: any;
    stock: number;
    target_age?: any;
    target_german_speaking?: boolean;
    target_nationality?: any;
    target_rating?: any;
}

export class InfluencerDeal {
    id: number;
    rating: string;
    price: any;
    post_count: number;
    post_type: string;
}